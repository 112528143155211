// Libs
import React, { useState } from 'react';
import { Map, Marker } from 'google-maps-react';
// Assets,
import selectedMarker from '../../images/icons/map-pin-selected.svg';
import unselectedMarker from '../../images/icons/map-pin-unselected.svg';
import mapStyles from '../../data/mapStyle.json';
import { isEmpty, get } from 'lodash';

const StoreLocationMap = ({
  google,
  focusPoint,
  otherPoints,
  zoom,
  onStoreMarkerSelected,
  isLocationEmpty = false,
}) => {
  // Verify otherPoints is a viable array.
  const map = React.createRef();
  otherPoints = Array.isArray(otherPoints) ? otherPoints : [];
  // Set zoom level
  zoom = zoom || 12;
  const [containerStyle] = useState({
    width: '100%',
    height: '100%',
    position: 'relative',
  });
  const defaultCenter = { lat: 43.6539, lng: -79.3842 };
  const showActivePoint = get(focusPoint, 'title')
    ? { lat: focusPoint.lat, lng: focusPoint.lon }
    : focusPoint;
  const selectMarker = (index) => {
    onStoreMarkerSelected(otherPoints[index].nid);
  };
  return (
    <>
      <Map
        google={google}
        zoom={zoom}
        styles={mapStyles}
        containerStyle={containerStyle}
        initialCenter={!isEmpty(showActivePoint) ? showActivePoint : defaultCenter}
        center={!isEmpty(showActivePoint) ? showActivePoint : defaultCenter}
        disableDefaultUI={true}
        ref={map}
      >
        {otherPoints.map((point, index) => (
          <Marker
            key={`mapPointer_${index}`}
            name={''}
            position={point}
            zIndex={index}
            icon={unselectedMarker}
            onClick={() => {
              selectMarker(index);
            }}
          />
        ))}
        {!isEmpty(focusPoint) && (
          <Marker
            name={focusPoint.title}
            zIndex={otherPoints.length}
            position={showActivePoint}
            icon={selectedMarker}
          />
        )}
      </Map>
    </>
  );
};

export default StoreLocationMap;
