// Libs.
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
// Custom Libs.
import {
  getInactiveStoresPoints,
  boostMyStore,
  calculateStoresDistance,
  getStore,
  storeDrupalId,
} from '../../libs/store/store-utils';
// Deps.
import StoreListingItem from './store-listing-item';
import FindStoreNear from './find-store-near';
// Hooks.
import useGoogleMapsLoader from '../../hooks/useGoogleMapsLoader';
import { useDefaultStore } from '../../hooks/useDefaultStore';
// Assets.
import styles from './store-listing.module.scss';
import PagePreloader from '../common/loaders/page-preloader';
import { orderBy, findIndex, isEmpty, isNull, get } from 'lodash';
import StoreLocationMap from './store-location-map';
import { useToggle } from '../../hooks/useToggle';
import { getViewingPlatform } from '../../libs/getViewingPlatform';

const StoreListing = ({ stores, query }) => {
  const { iOS } = getViewingPlatform();

  const googleMapsLoad = useGoogleMapsLoader();
  const variation = 'app';
  // Steps to check
  // 1- if I have my store selected, my store should be first and highlighted on the map
  //    and the rest of the stores ordered by distance
  // 2- if I don't have a selected store, but location is enabled (query is not empty)
  //    choose the nearest store id and set that store as selected and the rest ordered by distance
  // 3- if no default store and no location, nothing is selected and everything is ordered alphabetically

  // My default store state.
  let { storeId, nearestStoreId } = useDefaultStore();
  const isLocationEmpty = isEmpty(query);
  const [myStoreId, setMyStoreID] = useState( null);

  const [storesList, setStoresList] = useState([]);
  const [activeStoreIdx, setActiveStoreIdx] = useState(null);
  const [activeStorePoint, setActiveStorePoint] = useState({});
  const [inactiveStoresPoints, setInactiveStorePoints] = useState({});
  const [searchStore, setSearchStore] = useState(false);

  useEffect(() => {
    // console.log('++', isLocationEmpty, storeId, nearestStoreId);
    setMyStoreID(storeId ? storeId : (isLocationEmpty ?  nearestStoreId || null : null));
  }, [storeId, nearestStoreId]);


  useEffect(() => {
    // console.log(':::', activeStorePoint);
  }, [activeStorePoint]);

  // Effect to apply my store boost relavance list to state,
  // when default store change.
  useEffect(() => {
    // console.log(':::', myStoreId);
    let sortedStoreList;
    let storesWithDistance;
    const myStore = myStoreId ? getStore(stores, myStoreId) : null;
    if (myStore) {
      storesWithDistance = calculateStoresDistance(stores, {
        latitude: parseFloat(myStore.latitude),
        longitude: parseFloat(myStore.longitude),
      });
      sortedStoreList = orderBy(storesWithDistance, ['distance'], ['asc']);
      sortedStoreList = boostMyStore(sortedStoreList, myStoreId);
      setStoresList(sortedStoreList);
      setActiveStoreIdx(0);
      setActiveStorePoint({
        lat: parseFloat(myStore.lat),
        lon: parseFloat(myStore.lon),
        title: myStore.title
      });
      setInactiveStorePoints(
        getInactiveStoresPoints(sortedStoreList, findIndex(sortedStoreList, sortedStoreList[0]))
      );
    } else if (!isLocationEmpty) {
      storesWithDistance = calculateStoresDistance(stores, {
        latitude: parseFloat(get(query, 'latitude')),
        longitude: parseFloat(get(query, 'longitude')),
      });
      sortedStoreList = orderBy(storesWithDistance, ['distance'], ['asc']);
      setStoresList(sortedStoreList);
      setActiveStoreIdx(0);
      setActiveStorePoint({
        lat: parseFloat(sortedStoreList[0].lat),
        lon: parseFloat(sortedStoreList[0].lon),
        title: sortedStoreList[0].title
      });
      setInactiveStorePoints(
        getInactiveStoresPoints(sortedStoreList, findIndex(sortedStoreList, sortedStoreList[0]))
      );
    } else {
      storesWithDistance = calculateStoresDistance(stores, {
        latitude: 43.6539,
        longitude: -79.3842,
      });
      setStoresList(storesWithDistance);
      sortedStoreList = orderBy(storesWithDistance, ['name']);
      setActiveStoreIdx(null);
      setActiveStorePoint({});
      setInactiveStorePoints(getInactiveStoresPoints(sortedStoreList, -1));
    }
  }, [myStoreId]);

  // Handle stores list change, triggers when reference address change.
  const handleSetStoresList = (storesList) => {
    setStoresList(storesList);
    setSearchStore(true);
    setActiveStoreIdx(0);
    setActiveStorePoint({
      lat: parseFloat(storesList[0].lat),
      lon: parseFloat(storesList[0].lon),
      title: storesList[0].title
    });
    setInactiveStorePoints(
      getInactiveStoresPoints(storesList, findIndex(storesList, storesList[0]))
    );
  };

  // TODO: Integrate with user location state to determine if available.

  const onStoreMarkerSelected = (nid) => {
    const [selectedStore] = stores.filter((store) => store.nid === nid);

    const newList = calculateStoresDistance(stores, {
      latitude: selectedStore.latitude,
      longitude: selectedStore.longitude,
    });

    const newSortedList = orderBy(newList, ['distance'], ['asc']);
    setStoresList(newSortedList);
    setActiveStoreIdx(0);
    setActiveStorePoint({ lat: parseFloat(selectedStore.lat), lng: parseFloat(selectedStore.lon) });
  };

  if (!Array.isArray(storesList) || storesList.length === 0) {
    return <div>No results found.</div>;
  }
  // Stop rendering until Google Places Lib is fully loaded and my default store
  // is resolved.
  if (!googleMapsLoad) {
    return (
      <div className={styles.storeListingBackground}>
        <div className={styles.storeListingContainer}>
          <PagePreloader />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.storeListingBackground} data-style={variation}>
      <div className={styles.storeListingContainer}>
        <FindStoreNear storesList={storesList} setStoresList={handleSetStoresList} />
        <div className={styles.storeListingMap}>
          <StoreLocationMap
            google={window.google}
            focusPoint={activeStorePoint}
            otherPoints={inactiveStoresPoints}
            zoom={10}
            isLocationEmpty={isLocationEmpty}
            onStoreMarkerSelected={onStoreMarkerSelected}
          />
        </div>
        <div>
          <div className={`${styles.storeListingList} ${iOS ? styles.storeListingListIos : styles.storeListingListNonIos}`}>
            {storesList.map((store, idx) => (
              <StoreListingItem
                query={!isLocationEmpty ? true : isLocationEmpty && searchStore}
                key={store.id}
                store={store}
                idx={idx}
                myStoreId={isEmpty(query) ? String(myStoreId) : String(storeId)}
                activeStoreIdx={activeStoreIdx || 0}
                setActive={setActiveStoreIdx}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

StoreListing.propTypes = {
  stores: PropTypes.array.isRequired,
};

export default StoreListing;
