// Libs
import React, { useState } from 'react';
import moment from 'moment';
// Deps.
import styles from './app-store-details.module.scss';
import StoreWhatsInContent from '../common/store/store-what-in-content';
import StoreSetAction from '../store/store-set-action';
import { storeDrupalId } from '../../libs/store/store-utils';
import StoreLocationMap from './store-location-map';
import StoreHours from '../common/store/store-hours';
import { isEmpty, sortBy, get } from 'lodash';
import StoreHolidayHours from '../common/store/store-holiday-hours';
import Img from 'gatsby-image';

const AppStoreDetails = ({ store }) => {
  const [hoursExpanded, setHoursExpanded] = useState(false);
  const [storeHoursExpanded, setStoreHoursExpanded] = useState(true);
  const phoneLink = `tel:${store.field_store_phone}`;
  const address = `${store.address1}, ${store.city}, CA`;
  const imagePath = get(
    store,
    'relationships.field_store_features[0].relationships.field_sf_image.localFile.childImageSharp.fluid',
    false
  );
  let holidays = get(store, 'relationships.field_holidays', false);
  if (!isEmpty(holidays)) {
    holidays = holidays.filter((holiday) =>  moment(holiday.date, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day') );
  }

  const handleHoursExpanded = (e) => {
    setHoursExpanded(!hoursExpanded);
  };
  // let correctDays = [];
  // const days = store.storeHours;
  // if (store.storeHours && store.storeHours.length > 0 ) {
  //   const sevenDay = days[0];
  //   correctDays = days.slice(1);
  // }
  const dropdownBackgroundColors = ['#f7f7f7', '#f8f4ed'];
  let colorIndex = 0;

  return (
    <div>
      <div className={styles.appStoreDetailsMap}>
        <StoreLocationMap
          google={window.google}
          focusPoint={{
            lat: store.latitude || '43.8271162',
            lng: store.longitude || '-79.5590672',
          }}
          zoom={14}
        />
      </div>
      <div className={styles.appStoreDetails}>
        <div className={styles.appStoreDetailsSetStore}>
          <h1>{store.title}</h1>
          <StoreSetAction storeId={storeDrupalId(store)} />
        </div>
        <div className={styles.appStoreDetailsAddress}>
          <span className={styles.appStoreDetailsAddressName}>{address}</span>
          {!!address && (
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${address}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Directions
            </a>
          )}
        </div>
        <div className={styles.appStoreDetailsPhoneNumbers}>
          <a href={phoneLink}>{store.field_store_phone}</a>
        </div>
        <div className={styles.appStoreDetailsTextbox}>
          {store.field_store_text_box}
        </div>
        {!isEmpty(store.storeHours) && (<div className={styles.appStoreDetailsHours} data-expanded={storeHoursExpanded}>
          <div onClick={() => setStoreHoursExpanded(!storeHoursExpanded)}>
            <h3>Store hours</h3>
          </div>
          {storeHoursExpanded && <StoreHours storeHours={sortBy(store.storeHours, ['day'])} />}
        </div>)}

        {!isEmpty(store.field_store_pharmacy_hours) && (
          <div className={styles.appStoreDetailsPharmacyHours} style={{backgroundColor: dropdownBackgroundColors[colorIndex++] }}>
            <h3
              role="button"
              tabIndex="0"
              onClick={handleHoursExpanded}
              data-visibility={hoursExpanded ? 'visible' : 'hidden'}
            >
              Pharmacy Hours
            </h3>
            <div
              className={
                styles.appStoreWhatsInItemListContainer +
                ' ' +
                (hoursExpanded
                  ? styles.appStoreWhatsInItemListContainerExpanded
                  : styles.appStoreWhatsInItemListContainerCollapsed)
              }
            >
              <StoreHours storeHours={sortBy(store.field_store_pharmacy_hours, ['day'])} />
            </div>
          </div>
        )}
        {!isEmpty(holidays) && (
          <div className={styles.appStoreDetailsHolidayHours} style={{backgroundColor: dropdownBackgroundColors[colorIndex++] }}>
            <h3 role="button" tabIndex="0" onClick={handleHoursExpanded}>
              Holiday Hours
            </h3>
            <div
              className={
                styles.appStoreWhatsInItemListContainer +
                ' ' +
                (hoursExpanded
                  ? styles.appStoreWhatsInItemListContainerExpanded
                  : styles.appStoreWhatsInItemListContainerCollapsed)
              }
            >
              <StoreHolidayHours store={store} />
            </div>
          </div>
        )}
      </div>
      {imagePath && (
        <Img className={styles.appStoreWhatsInItemImage} fluid={imagePath} alt="Placeholder" />
      )}
      <div className={styles.appStoreDetails}>
        <StoreWhatsInContent store={store} />
      </div>
    </div>
  );
};

export default AppStoreDetails;
