// Libs.
import React from 'react';
import {get, sortBy, isEmpty} from 'lodash';
// Deps.
import AppLayout from '../../components/layout/AppLayout';
import SEO from '../../components/layout/seo';
import StoreListing from '../../components/app/store-listing';
// State
import { useStoresStaticQueryFindStore } from '../../hooks/useStoresStaticQueryFindStore';
import AppStoreDetails from "../../components/app/app-store-details";
import NavigationDrawer, {useDrawer} from "../../hooks/useNavigationDrawer";
import {useStateValue} from "../../store/state";
import { getViewingPlatform } from '../../libs/getViewingPlatform';

const FindStore = () => {
  const { iOS } = getViewingPlatform();
  const [{user}] = useStateValue();
  const [drawerStatus] = useDrawer([]);
  const stores = sortBy(useStoresStaticQueryFindStore(), (s) => {
    return s.name;
  });
  const drawerStore = stores.find(store => drawerStatus.includes(`${store.nid}`) || drawerStatus.includes(store.nid));
  const query = {};
  if (get(user, 'latitude') && get(user, 'longitude')) {
    if (get(user,'latitude') !== 'undefined') {
      query.latitude = get(user,'latitude');
      query.longitude = get(user, 'longitude');
    }
  }
  return (
    <AppLayout>
      <SEO title="Find a store"/>
      {iOS && <div style={{ height: 40, backgroundColor: '#404041' }}></div>}
      <StoreListing stores={stores} query={query}/>
      <NavigationDrawer
        header={'All Stores'}
        drawerStatus={!isEmpty(drawerStore)}>
        {drawerStore && <AppStoreDetails store={drawerStore}/>}
      </NavigationDrawer>
    </AppLayout>
  );
};

export default FindStore;
