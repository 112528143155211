// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
// Components.
import StoreSetAction from '../store/store-set-action';
// Custom Libs.
import { storeDrupalId } from '../../libs/store/store-utils';
import { redirectKeyPressHandler } from '../../libs/accessibility';
// Assets.
import styles from './store-listing-item.module.scss';
import locationIcon from '../../images/icons/icon-location-red.svg';
import StoreOpenNotice from '../store/store-open-notice';
import {useDrawer} from "../../hooks/useNavigationDrawer";

function StoreListingItem({ store, idx, activeStoreIdx, setActive, myStoreId, query = true }) {
  const destination = encodeURI(`${store.address1}, ${store.city}, CA`);
  const [,navigateDrawer] = useDrawer([]);
  const directionUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
  const phoneUrl = `tel:+1${store.phone}`;
  const currentIsActive = idx === activeStoreIdx;
  // Prevent propagation to stop collapse when click on links or buttons.
  const handleClick = (e) => {
    navigateDrawer(store.nid);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleItemCollapsible = (idx) => {
    if (currentIsActive) {
      // If currently active, set negative index to collapse all.
      setActive(-1);
    } else {
      setActive(idx);
    }
  };
  const isCurrentStore = parseInt(storeDrupalId(store)) === parseInt(myStoreId);
  return (
    <div
      role="button"
      tabIndex="0"
      onClick={(e) => handleItemCollapsible(idx)}
      onKeyPress={redirectKeyPressHandler(() => handleItemCollapsible(idx))}
      className={styles.storeListingItemRow}
    >
      {query && idx === 0 && store.distance !== undefined && (
        <span className={styles.storeListingItemTag}>Closest store</span>
      )}
      {isCurrentStore && <span className={styles.storeListingItemTag}>My store</span>}
      <div
        data-main-visibility={currentIsActive ? 'visible' : 'hidden'}
        className={styles.storeListingItemHeader}
      >
        <h2 className={styles.storeListingItemName}>{store.name}</h2>
        {query && store.distance !== undefined && (
          <div className={styles.storeListingItemDistance}>
            <span>{store.distance.toFixed(1)}</span>
            <span>km</span>
          </div>
        )}
      </div>
      <div
        data-visibility={currentIsActive ? 'visible' : 'hidden'}
        className={styles.storeListingItemDetail}
      >
        <ul>
          <li>
            <StoreOpenNotice store={store} />
          </li>
          <li>
            {store.address1}, {store.city}
          </li>
          <li className={styles.storeListingItemDirections}>
            <a
              title="Directions"
              href={directionUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              Directions
            </a>
          </li>
          <li className={styles.storeListingItemPhone}>
            <a onClick={(e) => e.stopPropagation()} href={phoneUrl}>
              {store.phone}
            </a>
          </li>
          <li className={styles.storeListingItemChangeStore}>
            <StoreSetAction storeId={storeDrupalId(store)} label="Save this store" />
          </li>
        </ul>
        {navigateDrawer ? (
          <button
            onClick={handleClick}
            className={styles.storeListingItemViewDetails}
          >
            Store services
          </button>
        ) : (
          <Link
            onClick={handleClick}
            className={styles.storeListingItemViewDetails}
            to={store.alias}
          >
            View Details
          </Link>
        )}
      </div>
    </div>
  );
}

StoreListingItem.propTypes = {
  store: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  activeStoreIdx: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
  myStoreId: PropTypes.string.isRequired,
};

export default StoreListingItem;
