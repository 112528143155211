// Libs.
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
// Custom Libs.
import { calculateStoresDistance } from '../../libs/store/store-utils';
// Assets.
import styles from './find-store-near.module.scss';
import PagePreloader from '../common/loaders/page-preloader';

const FindStoreNear = ({ storesList, setStoresList }) => {
  const [address, setAddress] = useState('');

  const handleAddressChange = (address) => {
    setAddress(address);
  };

  const handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((selectAddress) => {
        const storesWithDistance = calculateStoresDistance(storesList, {
          latitude: selectAddress[0].geometry.location.lat(),
          longitude: selectAddress[0].geometry.location.lng(),
        });

        const storesByDistance = orderBy(storesWithDistance, ['distance'], ['asc']);

        // Set selected store and stores lists sorted by distance into state.
        setAddress(selectAddress[0].formatted_address);
        setStoresList(storesByDistance);
      })
      .catch((e) => {
        console.log('Geocoding error:', e);
      });
  };

  const debounceMs = 500;
  const searchOptions = {
    // Bias autocomplete to a radius of 100kms from Toronto.
    location: new window.google.maps.LatLng(43.6539, -79.3842),
    radius: 100000,
  };

  return (
    <div className={styles.findStoreNearContainer}>
      <label>Find a store near</label>
      <PlacesAutocomplete
        value={address}
        onChange={handleAddressChange}
        onSelect={handleAddressSelect}
        searchOptions={searchOptions}
        debounce={debounceMs}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Enter address, city or postal code...',
                'aria-label': 'Enter address, city or postal code...',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <PagePreloader />}
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                return (
                  <div
                    key={suggestion.id}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

FindStoreNear.propTypes = {
  storesList: PropTypes.array.isRequired,
  setStoresList: PropTypes.func.isRequired,
};

export default FindStoreNear;
